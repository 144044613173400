#paFrame {
  border: none;
  width: 100%;
  min-height: 755px; }

#deviceDataCollection {
  margin: 0 auto; }

#paymentprocessingmessage {
  text-align: center;
  padding: 1em; }

#deviceFPC {
  width: 100px;
  height: 100px;
  border: 0;
  position: absolute;
  top: -5000px; }
